import { ReactNode, useEffect } from "react";

export function SaveVisit({ children }: { children: ReactNode }) {
  useEffect(() => {
    fetch("https://api.ipify.org?format=json").then((resp) =>
      resp.json().then((data) => {
        const form = new FormData();
        form.append("ip", data.ip);
        form.append("address", window.location.href);
        fetch("/api/save-visit/", {
          method: "POST",
          body: form,
        });
      })
    );
  }, []);

  return <>{children}</>;
}
