import { ReactNode, useEffect, useRef, useState } from "react";

export function ListWithArrows({
  children,
  className,
  scrollMultiplier,
}: {
  children: ReactNode;
  className?: string;
  scrollMultiplier: number;
}) {
  const itemsContainer = useRef<HTMLDivElement>(null);
  const windowSize = window.innerWidth;
  const LArr = useRef<HTMLDivElement>(null);
  const RArr = useRef<HTMLDivElement>(null);
  const [itemSize, setItemSize] = useState<number | null>(null);
  const [curIndex, setCurIndex] = useState<number>(0);

  useEffect(() => {
    if (itemsContainer.current) {
      setItemSize(
        (itemsContainer.current.querySelector(".item") as HTMLElement)
          .offsetWidth
      );
    }
  }, [windowSize]);

  const onRArrowClick = () => {
    setCurIndex((prev) => (prev < 2 ? prev + 1 : prev));

    if (itemsContainer.current && itemSize && RArr.current && LArr.current) {
      if (curIndex !== 2) {
        itemsContainer.current.scrollLeft += itemSize * scrollMultiplier;
      }

      if (curIndex + 1 === 2) {
        RArr.current.classList.add("end");
      } else {
        LArr.current.classList.remove("end");
      }
    }
  };

  const onLArrowClick = () => {
    setCurIndex((prev) => (prev > 0 ? prev - 1 : prev));
    if (itemsContainer.current && itemSize && LArr.current && RArr.current) {
      if (curIndex !== 0) {
        itemsContainer.current.scrollLeft -= itemSize * scrollMultiplier;
      }

      if (curIndex - 1 === 0) {
        LArr.current.classList.add("end");
      } else {
        RArr.current.classList.remove("end");
      }
    }
  };

  return (
    <div className={`list-with-arrows ${className}`}>
      <div ref={LArr} onClick={onLArrowClick} className="arrow end">
        <svg viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.5207 0.468629C18.1598 1.09347 18.1598 2.10653 17.5207 2.73137L3.95053 16L17.5207 29.2686C18.1598 29.8935 18.1598 30.9065 17.5207 31.5314C16.8817 32.1562 15.8456 32.1562 15.2066 31.5314L0.47928 17.1314C-0.15976 16.5065 -0.15976 15.4935 0.47928 14.8686L15.2066 0.468629C15.8456 -0.15621 16.8817 -0.15621 17.5207 0.468629Z"
            fill="#B5ABA6"
          />
        </svg>
      </div>
      <div className="items-parent">
        <div ref={itemsContainer} className="items">
          {children}
        </div>
      </div>

      <div ref={RArr} onClick={onRArrowClick} className="arrow">
        <svg viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.47928 31.5314C-0.15976 30.9065 -0.15976 29.8935 0.47928 29.2686L14.0495 16L0.47928 2.73137C-0.15976 2.10653 -0.15976 1.09347 0.47928 0.468628C1.11832 -0.156212 2.15441 -0.156212 2.79345 0.468628L17.5207 14.8686C18.1598 15.4935 18.1598 16.5065 17.5207 17.1314L2.79345 31.5314C2.15441 32.1562 1.11832 32.1562 0.47928 31.5314Z"
            fill="#B5ABA6"
          />
        </svg>
      </div>
    </div>
  );
}
