import React, { useState } from "react";
import "./order.css";
import { mediaPath } from "../Home";

export function Order({ onClose }: { onClose: () => void }) {
  const [error, setError] = useState("");
  const [data, setData] = useState({
    contact: "",
    wishes: "",
    budgetFrom: "",
    budgetTo: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setError("");
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const send = () => {
    const { contact, wishes, budgetFrom, budgetTo } = data;

    const formData = new FormData();
    formData.append("contact", contact);
    formData.append("wishes", wishes);
    formData.append("budget", `${budgetFrom}-${budgetTo}`);

    fetch("/api/make-order/", {
      method: "POST",
      body: formData,
    })
      .then((resp) => {
        if (!resp.ok) {
          throw Error("Вы неверно заполнили форму!");
        }
        return resp.json();
      })
      .then((response) => {
        onClose();
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <div
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
      className="application"
    >
      <div className="bg-text-left">
        <img src={mediaPath("orderBgLeft.png")} alt="" />
      </div>
      <div className="title"> Оставить заявку</div>
      <div className="fields">
        <div className="error">{error}</div>
        <div className="box">
          <div className="text">Пожелания</div>
          <textarea name="wishes" onChange={handleInputChange}></textarea>
          <div className="text">Бюджет</div>
          <div className="input-number-parent">
            <div className="input-number">
              от
              <input
                type="number"
                name="budgetFrom"
                onChange={handleInputChange}
              />
              до
              <input
                type="number"
                name="budgetTo"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="text">
            Куда прислать ответ{window.innerWidth < 600 ? <br /> : null}
            (телеграм/почта/телефон)
          </div>
          <div className="answer-parent">
            <div className="answer">
              <input name="contact" onChange={handleInputChange} />
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => send()} className="button">
        Отправить
      </div>
      <div className="bg-text-right">
        <img src={mediaPath("orderBgRight.png")} alt="" />
      </div>
    </div>
  );
}
