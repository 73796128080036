import { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import { getPosition } from "../../utils";
import { AppearanceAnimated } from "./Animations";

import {
  ProjectType,
  ProjectsDataType,
  isMobile,
  isTablet,
  mediaPath,
} from "../Home";
import { ItemsType, moveBack, moveForward } from "./CarouselHelpers";
import { ListWithArrows } from "./ListWithArrows";

interface MobCaseProps {
  projects: ProjectType[];
  bgImg: string;
  title: string;
  type: string;
  itemsClassName: string;
  setSelectedProject: React.Dispatch<React.SetStateAction<ProjectType | null>>;
}

interface CaseProps extends MobCaseProps {
  htmlSelector: string;
}

function Case({
  projects,
  bgImg,
  title,
  type,
  itemsClassName,
  setSelectedProject,
  htmlSelector,
}: CaseProps) {
  const slideTrashHold = isMobile ? 5 : 40;
  const carouselRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  console.log("rerend");

  const setCanOpen = (value: boolean) => {
    const secondClassList = document.querySelector(
      `.${htmlSelector} .second`
    )?.classList;
    if (secondClassList) {
      if (value) {
        secondClassList.add("can-open");
      } else {
        secondClassList.remove("can-open");
      }
    }
  };

  function onDragStart(e: any) {
    e.target.dataset.startx = getPosition(e, "X");
    e.target.classList.add("drag-target");
  }

  function onDragEnd(e: any | undefined) {
    (e.target.style as any).cursor = "grab";

    if (e.target.closest(".second.can-open")) {
      const id = Number(e.target.closest(".second").dataset.projid);
      const openProject = projects.find((proj) => proj.id === id);
      if (openProject) {
        setSelectedProject(openProject);
      } else {
        setSelectedProject(null);
      }
      setCanOpen(false);
    } else {
      setSelectedProject(null);
      setCanOpen(true);
    }
    e.target.classList.remove("drag-target");
  }

  function getItems(): ItemsType | undefined {
    const projects = document.querySelector(`.${htmlSelector}`);

    if (projects) {
      const [first, second, third] = [
        projects.querySelector(".carousel .first"),
        projects.querySelector(".carousel .second"),
        projects.querySelector(".carousel .third"),
      ];
      if (first && second && third) {
        return {
          first: first,
          second: second,
          third: third,
        };
      }
    }
  }

  function move(difference: number, target?: any) {
    const items: ItemsType | undefined = getItems();
    if (!items) return;

    if (difference > 0) {
      moveForward(items);
    } else {
      moveBack(items);
    }
    target?.classList?.remove("drag-target");
  }

  function onDragMove(e: any) {
    if (e.target.classList.contains("drag-target")) {
      (e.target.style as any).cursor = "grabbing";

      const difference = Number(e.target.dataset.startx) - getPosition(e, "X");
      if (Math.max(difference, difference * -1) > slideTrashHold) {
        setCanOpen(false);
        move(difference, e.target);
      }
    }
  }

  useEffect(() => {
    function handleAnimation(count: number) {
      const interval = setInterval(() => {
        move(100);
        if (count > 0) {
          handleAnimation(count - 1);
        }
        clearInterval(interval);
      }, 1000);
    }

    function handleIntersection(entries: any, observer: any) {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting && carouselRef.current) {
          move(100);
          handleAnimation(0);
          observer.unobserve(entry.target);
        }
      });
    }

    const options = {
      root: null,
      rootMargin: type === "bot" ? "600px" : "0px",
      threshold: 0.8,
    };

    if (!isMobile && !isTablet) {
      const observer = new IntersectionObserver(handleIntersection, options);

      if (carouselRef.current) {
        observer.observe(carouselRef.current);
      }

      return () => {
        if (carouselRef.current) {
          observer.unobserve(carouselRef.current);
        }
      };
    }
  }, []);

  const cssClasses = ["first", "second", "third"];

  return (
    <div className={`anim-down case ${htmlSelector}`}>
      <AppearanceAnimated className="anim-scale case-name">
        {title}
      </AppearanceAnimated>
      <div className={`projects`}>
        <img src={bgImg} className={`${itemsClassName} bg-img`} alt="" />
        <div className="bg-blur-parent">
          <div className="blur"></div>
        </div>
        <div className="carousel-parent">
          <div ref={carouselRef} className="carousel">
            {projects.map((proj: ProjectType | null, index: number) => (
              <div
                data-projid={proj?.id}
                key={type + "_" + index}
                onMouseDown={onDragStart}
                onTouchStart={onDragStart}
                onMouseUp={onDragEnd}
                onTouchEnd={onDragEnd}
                onMouseMove={onDragMove}
                onTouchMove={onDragMove}
                className={`item ${itemsClassName} ${cssClasses[index]} ${
                  cssClasses[index] === "second" ? "can-open" : ""
                }`}
              >
                {proj && (
                  <>
                    <div className="img-box">
                      <img
                        loading="lazy"
                        onDragStart={(e) => e.preventDefault()}
                        src={proj.image}
                        alt=""
                      />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
interface CasesProps {
  setSelectedProject: React.Dispatch<React.SetStateAction<ProjectType | null>>;
  projectsData: ProjectsDataType;
}

export function Cases({ projectsData, setSelectedProject }: CasesProps) {
  return (
    <div className="cases">
      <AppearanceAnimated className="anim-left title">КЕЙСЫ</AppearanceAnimated>
      <Case
        htmlSelector="html-designs"
        setSelectedProject={setSelectedProject}
        projects={projectsData.designs}
        bgImg={mediaPath("bgDesign.png")}
        title="ДИЗАЙН САЙТОВ"
        itemsClassName="design"
        type="design"
      />
      <Case
        htmlSelector="html-bots"
        setSelectedProject={setSelectedProject}
        projects={projectsData.bots}
        bgImg={mediaPath("bgChatBot.png")}
        title="ЧАТ БОТЫ"
        itemsClassName="bot"
        type="bot"
      />
      {projectsData.stickers.length > 0 && (
        <div className="case">
          <AppearanceAnimated className="anim-left case-name">
            СТИКЕРЫ
          </AppearanceAnimated>
          <img
            src={mediaPath("bgSticker.png")}
            className="bg-sticker-img"
            alt=""
          />
          <div className="stickers-parent">
            {projectsData.stickers.map((sticker, index) => (
              <AppearanceAnimated
                key={"sticker_" + index}
                className="sticker anim-down"
              >
                <img
                  onClick={() => setSelectedProject(sticker)}
                  key={index}
                  src={sticker.image}
                  alt=""
                />
              </AppearanceAnimated>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function MobCase({
  projects,
  bgImg,
  title,
  type,
  itemsClassName,
  setSelectedProject,
}: MobCaseProps) {
  return (
    <div>
      <AppearanceAnimated className="anim-scale case-name">
        {title}
      </AppearanceAnimated>
      <ListWithArrows className={itemsClassName} scrollMultiplier={1}>
        {projects.map((proj) => (
          <div className="item">
            {proj && (
              <>
                <div className="img-box">
                  <img
                    loading="lazy"
                    onClick={() => setSelectedProject(proj)}
                    onDragStart={(e) => e.preventDefault()}
                    src={proj.image}
                    alt=""
                  />
                </div>
              </>
            )}
          </div>
        ))}
      </ListWithArrows>
    </div>
  );
}
export function MobileCases({ projectsData, setSelectedProject }: CasesProps) {
  return (
    <>
      <div className="mob-cases">
        <AppearanceAnimated className="anim-left title">
          КЕЙСЫ
        </AppearanceAnimated>
        <MobCase
          setSelectedProject={setSelectedProject}
          projects={projectsData.designs}
          bgImg={mediaPath("bgDesign.png")}
          title="ДИЗАЙН САЙТОВ"
          itemsClassName="design"
          type="design"
        />
        <MobCase
          setSelectedProject={setSelectedProject}
          projects={projectsData.bots}
          bgImg={mediaPath("bgChatBot.png")}
          title="ЧАТ БОТЫ"
          itemsClassName="bot"
          type="bot"
        />
      </div>
      <div className="cases">
        {projectsData.stickers.length > 0 && (
          <div className="case">
            <AppearanceAnimated className="anim-left case-name">
              СТИКЕРЫ
            </AppearanceAnimated>
            <img
              src={mediaPath("bgSticker.png")}
              className="bg-sticker-img"
              alt=""
            />
            <div className="stickers-parent">
              {projectsData.stickers.map((sticker, index) => (
                <AppearanceAnimated
                  key={"sticker_" + index}
                  className="sticker anim-down"
                >
                  <img
                    onClick={() => setSelectedProject(sticker)}
                    key={index}
                    src={sticker.image}
                    alt=""
                  />
                </AppearanceAnimated>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
