import { useEffect } from "react";

export const getPosition = (
  event: TouchEvent | MouseEvent,
  direction?: string
) => {
  let currentPosition;
  if (
    (event as TouchEvent).touches &&
    (event as TouchEvent).touches.length === 1
  ) {
    if (direction === "Y") {
      currentPosition = (event as TouchEvent).touches[0].clientY;
    } else {
      currentPosition = (event as TouchEvent).touches[0].clientX;
    }
  } else {
    if ("Y" === direction) {
      currentPosition = (event as MouseEvent).clientY;
    } else {
      currentPosition = (event as MouseEvent).clientX;
    }
  }
  return currentPosition;
};
