export interface ItemsType {
  first: HTMLElement | Element | null | undefined;
  second: HTMLElement | Element | null | undefined;
  third: HTMLElement | Element | null | undefined;
}

export function moveForward(items: ItemsType) {
  items?.first?.classList.add("third");
  items?.first?.classList.remove("first");

  items?.third?.classList.add("second");
  items?.third?.classList.remove("third");

  items?.second?.classList.add("first");
  items?.second?.classList.remove("second");
}

export function moveBack(items: ItemsType) {
  items?.third?.classList.add("first");
  items?.third?.classList.remove("third");

  items?.first?.classList.add("second");
  items?.first?.classList.remove("first");

  items?.second?.classList.add("third");
  items?.second?.classList.remove("second");
}
